//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    tab: 'kerry',
    snackbar: false,
    snackbar_text: '',
    import_dialog: false,
    reset_D_dialog: false,
    assign_work_dialog: false,
    assign_work_dialog_month: false,
    import_file: null,
    loading_kerry: false,
    loading_sale: false,
    loading_reset_d: false,
    search_keyword: '',
    dataPool : [],
    dataPool_status_b : [],
    removeReasonData : [],
    removeReasonDataTemplate: [],
    appointmentData : [],
    saleSelected: {},
    saleTransactionData: [],
    leadListMonth: [],
    saleList: [],
    customer_detail: false,
    note: '',
    assign_note: '',
    month_filter: false,
    work_count: '20',
    select_month_info_month: '',
    select_month_info_source: '',
    select_month_info_status: '',
    selected_b: [],
    remove_reason_unique_item: '',
    remove_reason_unique: [],
    dataTableHeader : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'address1',
        align: 'start',
        value: 'address1',
      },
      {
        text: 'address2',
        align: 'start',
        value: 'address2',
      },
      {
        text: 'ตำบล',
        align: 'start',
        value: 'subdistrict',
      },
      {
        text: 'อำเภอ',
        align: 'start',
        value: 'district',
      },
      {
        text: 'จังหวัด',
        align: 'start',
        value: 'province',
      },
      {
        text: 'รหัสไปรษณีย์',
        align: 'start',
        value: 'postcode',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'มูลค่า (บาท)',
        align: 'start',
        value: 'amount',
      },
      {
        text: 'สถานะรายการ',
        align: 'start',
        value: 'status',
      },
      {
        text: 'วันนัดหมาย',
        align: 'start',
        value: 'noti_date',
      },
      {
        text: 'note',
        align: 'start',
        value: 'note',
      },
      {
        text: 'sale',
        align: 'start',
        value: 'username',
      },
      {
        text: 'source',
        align: 'start',
        value: 'source',
      },
      {
        text: 'product',
        align: 'start',
        value: 'product',
      },
      {
        text: 'โยนไป A',
        align: 'start',
        value: 'remove_from_b',
      },
      {
        text: 'deadline',
        align: 'start',
        value: 'deadline',
      },
    ],
    removeTableHeader : [
      {
        text: 'ชื่อพนักงาน',
        align: 'start',
        value: 'username',
      },
      {
        text: 'เบอร์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'วิธีการลบ',
        align: 'start',
        value: 'to_status',
      },
      {
        text: 'วันที่ลบ',
        align: 'start',
        value: 'date',
      },
      {
        text: 'เหตุผล',
        align: 'start',
        value: 'remove_reason',
      },
    ],
    leadListMonthHeader : [
      {
        text: 'เดือน',
        align: 'start',
        value: 'txn_month',
      },
      {
        text: 'จำนวนเบอร์ที่ยังเหลือ',
        align: 'start',
        value: 'monthly_sum',
      },
      {
        text: 'source',
        align: 'start',
        value: 'source',
      },
    ],
    appointmentTableHeader : [
      {
        text: 'ชื่อพนักงาน',
        align: 'start',
        value: 'username',
      },
      {
        text: 'เบอร์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'from',
        align: 'start',
        value: 'from',
      },
      {
        text: 'to',
        align: 'start',
        value: 'to',
      },
      {
        text: 'เหตุผล',
        align: 'start',
        value: 'note',
      },
    ],
    saleTransactionHeader : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'address1',
        align: 'start',
        value: 'address1',
      },
      {
        text: 'address2',
        align: 'start',
        value: 'address2',
      },
      {
        text: 'ตำบล',
        align: 'start',
        value: 'subdistrict',
      },
      {
        text: 'อำเภอ',
        align: 'start',
        value: 'district',
      },
      {
        text: 'จังหวัด',
        align: 'start',
        value: 'province',
      },
      {
        text: 'รหัสไปรษณีย์',
        align: 'start',
        value: 'postcode',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'ราคา (บาท)',
        align: 'start',
        value: 'price',
      },
      {
        text: 'ค่าใช้จ่ายอื่นๆ (บาท)',
        align: 'start',
        value: 'price_other',
      },
      {
        text: 'ประเภทสินค้า',
        align: 'start',
        value: 'product_type',
      },
      {
        text: 'รายการสินค้า',
        align: 'start',
        value: 'product',
      },
      {
        text: 'วิธีการชำระเงิน',
        align: 'start',
        value: 'payment_method',
      },
      {
        text: 'หมายเหตุ',
        align: 'start',
        value: 'note',
      },
      {
        text: 'เวลาส่งข้อมูล',
        align: 'start',
        value: 'insert_time',
      },
      {
        text: 'sale',
        align: 'start',
        value: 'username',
      },
      {
        text: 'sale',
        align: 'start',
        value: 'image_path',
      },
    ],
    customer_detail_sale_transaction: [],
    customer_detail_data_pool: [],
    customer_detail_data_pool_Header : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'วันที่รับข้อมูลเข้าระบบ',
        align: 'start',
        value: 'date',
      },
      {
        text: 'ที่อยู่',
        align: 'start',
        value: 'address1',
      },
      {
        text: 'จังหวัด',
        align: 'start',
        value: 'province',
      },
      {
        text: 'ราคา',
        align: 'start',
        value: 'amount',
      },
      {
        text: 'symbol',
        align: 'start',
        value: 'symbol',
      },
    ],
    customer_detail_sale_transaction_Header : [
      {
        text: 'id',
        align: 'start',
        value: 'id',
      },
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'บ้านเลขที่',
        align: 'start',
        value: 'address1',
      },
      {
        text: 'ตำบล',
        align: 'start',
        value: 'subdistrict',
      },
      {
        text: 'อำเภอ',
        align: 'start',
        value: 'district',
      },
      {
        text: 'จังหวัด',
        align: 'start',
        value: 'province',
      },
      {
        text: 'รหัสไปรษณีย์',
        align: 'start',
        value: 'postcode',
      },
      {
        text: 'วันที่สั่งสินค้า',
        align: 'start',
        value: 'insert_time',
      },
      {
        text: 'ชนิดผลิตภัณฑ์',
        align: 'start',
        value: 'product_type',
      },
      {
        text: 'ผลิตภัณฑ์',
        align: 'start',
        value: 'product',
      },
      {
        text: 'ราคา',
        align: 'start',
        value: 'price',
      },
      {
        text: 'ค่าใช้จ่ายอื่นๆ',
        align: 'start',
        value: 'price_other',
      },
      {
        text: 'วิธีการชำระเงิน',
        align: 'start',
        value: 'payment_method',
      },
    ],
    lead_month_info : [],
    lead_month_info_Header : [
      {
        text: 'สถานะ',
        align: 'start',
        value: 'status',
      },
      {
        text: 'จำนวนเบอร์',
        align: 'start',
        value: 'count',
      },
    ],
    lead_month_info_filter : [],
    lead_month_info_fulter_Header : [
      {
        text: 'สถานะ',
        align: 'start',
        value: 'status',
      },
      {
        text: 'จำนวนเบอร์',
        align: 'start',
        value: 'count',
      },
    ],
    assign_lead_dialog: false,
    assign_lead_dialog_multi: false,
    mobileSelected: '',
    nameSelected: '',
    loading_sale_user: false,
    date_pick: '',
    status: 'A',
    statusList: ['A', 'D', 'P', 'Z'],
    imagePath: null,
    source: [],
    sourceSelect: '',
    assign_source: [],
    assign_sourceSelect: '',
  }),
  mounted () {
    this.getLeadListAdmin()
    this.getSourceList()
    this.getSaleTransaciontAdmin()
    this.getSaleListAdmin()
    this.getSaleListMonth()
    this.getRemoveReasonAdmin()
    this.getAppointmentAdmin()
  },
  methods: {
    updateTransaction(value) {
      console.log(value)
      this.$store.commit('SET_adminUpdateTransaction', value)
      
      this.$router.push('/updateTransactionAdmin')
    },
    selectRow(value) {
      console.log('select row')
      console.log(value)
      // this.date_from = value.noti_date
      this.mobileSelected = value.mobile
      this.nameSelected = value.name
      this.assign_lead_dialog = true
    },
    selectRow_transaction(value) {
      console.log('select row')
      console.log(value)
      this.note = value.note
      this.mobileSelected = value.mobile
      this.imagePath = value.image_path_real
      this.customer_detail = true
      this.get_customer_detail()
    },
    selectRowMonth(value) {
      console.log('select month')
      console.log(value)
      //saleTransactionData
      this.select_month_info_month = value.txn_month
      this.select_month_info_source = value.source
      this.get_lead_month_info()
    },
    selectRowMonthFilter(value) {
      console.log('select month')
      console.log(value)
      //saleTransactionData
      this.select_month_info_status = value.status
      this.get_lead_month_info_filter()
    },
    openTransactionImage() {
      window.open(this.imagePath.replace("-", "_"))
    },
    async get_customer_detail () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      console.log()
      const customer_detail = await this.$store.dispatch('customerDetail', {
        uuid: user.uuid,
        mobile: this.mobileSelected
      })

      this.customer_detail_sale_transaction = customer_detail.saleTransaction
      this.customer_detail_data_pool = customer_detail.dataPool

      console.log(customer_detail)
    },
    async get_lead_month_info () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      console.log()
      const leadMonthInfoResult = await this.$store.dispatch('leadMonthInfo', {
        uuid: user.uuid,
        month: this.select_month_info_month,
        source: this.select_month_info_source
      })

      this.lead_month_info = leadMonthInfoResult
      this.assign_work_dialog_month = true

      console.log(leadMonthInfoResult)
    },
    async get_lead_month_info_filter () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      console.log()
      const leadMonthInfoResult = await this.$store.dispatch('leadMonthInfoFilter', {
        uuid: user.uuid,
        month: this.select_month_info_month,
        source: this.select_month_info_source,
        status: this.select_month_info_status
      })

      this.lead_month_info_filter = leadMonthInfoResult
      this.month_filter = true

      console.log(leadMonthInfoResult)
    },
    change_to_kerry_data() {
      this.tabs = 'kerry'
    },
    async getLeadListAdmin () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_kerry = true

      console.log(user)
      const lead_list_result = await this.$store.dispatch('getLeadListAdmin', {
        uuid: user.uuid,
        keyword: this.search_keyword,
      })

      this.dataPool = lead_list_result

      let dataPool_status_b = []
      for (let i = 0; i < lead_list_result.length; i++) {
        // console.log(lead_list_result)
        if (lead_list_result[i].status == 'B') {
          dataPool_status_b.push(lead_list_result[i])
        }
      }
      this.dataPool_status_b = dataPool_status_b
      this.loading_kerry = false

      console.log(lead_list_result)
    },
    async getRemoveReasonAdmin () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_kerry = true

      console.log(user)
      const remove_reason_result = await this.$store.dispatch('getRemoveReasonAdmin', {
        uuid: user.uuid,
        keyword: this.search_keyword,
      })

      this.removeReasonData = remove_reason_result
      this.removeReasonDataTemplate = remove_reason_result
      this.remove_reason_unique_item = 'all'

      let unique = [...new Set(remove_reason_result.map(item => item.remove_reason))]
      let unique2 = unique.filter(elements => {
        return elements !== null;
      });
      unique2.push("all");
      this.remove_reason_unique = unique2
    },
    change_remove_reason() {
      if (this.remove_reason_unique_item == 'all'){
        this.removeReasonData = this.removeReasonDataTemplate
      }
      else {
        // this.removeReasonData = this.removeReasonDataTemplate.filter(function (el) {
        //   return el.remove_reason === this.remove_reason_unique_item
        // })
        this.removeReasonData = this.removeReasonDataTemplate.filter((user) => {
          return user.remove_reason === this.remove_reason_unique_item
        })
      }
    },
    async getAppointmentAdmin () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_kerry = true

      console.log(user)
      const appointment_result = await this.$store.dispatch('getAppointmentAdmin', {
        uuid: user.uuid,
        keyword: this.search_keyword,
      })

      this.appointmentData = appointment_result
      // this.loading_kerry = false
      console.log(appointment_result)
    },
    async getSourceList () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_kerry = true
      
      const source_list_result = await this.$store.dispatch('getSourceList', {
        uuid: user.uuid,
      })

      this.source = source_list_result

      console.log(source_list_result)
    },
    async getSaleTransaciontAdmin () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale = true

      console.log(user)
      const lead_list_result = await this.$store.dispatch('getSaleTransaciontAdmin', {
        uuid: user.uuid,
        keyword: this.search_keyword,
      })

      this.saleTransactionData = lead_list_result
      this.loading_sale = false
    },
    async getSaleListAdmin () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale_user = true

      console.log(user)
      const sale_list_result = await this.$store.dispatch('getSaleListAdmin', {
        uuid: user.uuid,
      })

      this.saleList = sale_list_result
      this.loading_sale_user = false

      // console.log(saleList)
    },
    async getSaleListMonth () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale_user = true

      console.log(user)
      const sale_list_result = await this.$store.dispatch('getSaleListMonth', {
        uuid: user.uuid,
      })

      this.leadListMonth = sale_list_result
      // this.loading_sale_user = false
    },
    async exportCreateTransactionCSV () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      const url_path = await this.$store.dispatch('exportCreateTransactionCSV', {
        uuid: user.uuid,
      })

      // console.log(url_path)
      window.open(url_path);
    },
    async exportDataPoolPSCSV () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      const url_path = await this.$store.dispatch('exportDataPoolPSCSV', {
        uuid: user.uuid,
      })

      // console.log(url_path)
      window.open(url_path);
    },
    async resetDAndAssignCron () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      this.loading_reset_d = true
      const url_path = await this.$store.dispatch('resetDAndAssignCron', {
        uuid: user.uuid,
        current_work: 150,
      })
      this.loading_reset_d = false
      this.reset_D_dialog = false
      console.log(url_path)
    },
    async assign_mobile_to_sale () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      console.log(this.saleSelected)
      const sale_list_result = await this.$store.dispatch('assign_mobile_to_sale_admin', {
        uuid: user.uuid,
        mobile: this.mobileSelected,
        sale_id: this.saleSelected,
        date: this.date_pick,
        status: this.status,
        note: this.assign_note,
        source: this.sourceSelect,
      })
      
      this.sourceSelect = ''
      this.assign_lead_dialog = false
      this.getLeadListAdmin()

      console.log(sale_list_result)
    },
    async assign_mobile_to_sale_multi () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      let param_obj = {
        uuid: user.uuid,
        mobile: this.selected_b,
        sale_id: this.saleSelected,
        date: this.date_pick,
        status: this.status,
        note_admin: this.assign_note,
      }

      let params = this.objectToFormData(param_obj)

      console.log(user)
      console.log(this.saleSelected)
      const sale_list_result = await this.$store.dispatch('assign_mobile_to_sale_multi', params)
      
      this.selected_b = []
      this.assign_lead_dialog_multi = false
      this.getLeadListAdmin()

      console.log(sale_list_result)
    },
    async assign_work_manual () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      console.log(this.saleSelected)
      const sale_list_result = await this.$store.dispatch('assign_work_manual', {
        uuid: user.uuid,
        work_count: this.work_count,
        sale_id: this.saleSelected,
        source: this.sourceSelect,
      })

      this.assign_work_dialog = false
      this.getLeadListAdmin()

      console.log(sale_list_result)
    },
    async assign_work_manual_month () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      console.log(this.saleSelected)
      const sale_list_result = await this.$store.dispatch('assign_work_manual_month', {
        uuid: user.uuid,
        work_count: this.work_count,
        sale_id: this.saleSelected,
        month: this.select_month_info_month,
        source: this.select_month_info_source,
      })

      this.assign_work_dialog_month = false
      this.getSaleListMonth()

      console.log(sale_list_result)
    },
    objectToFormData (obj = {}) {
        let formData = new FormData()
            for (let key in obj) {
                if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
                for (let item in obj[key]) {
                    if (typeof obj[key][item] === 'object' && !(obj[key][item] instanceof File)) {
                        for (let index in obj[key][item]) {
                            formData.append(`${key}[${item}][${index}]`, obj[key][item][index])
                        }
                    } else {
                        formData.append(`${key}[${item}]`, obj[key][item])
                    }
                }
            } else {
                formData.append(key, obj[key])
            }
        }
        return formData
    },
    async importLead () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)

      let param_obj = {
        uuid: user.uuid,
        kerry_filename: null,
      }
      if (this.import_file != null) {
        param_obj.kerry_filename = this.import_file
        let params = this.objectToFormData(param_obj)
        const create_transaction_result = await this.$store.dispatch('importLead', params)
        console.log(create_transaction_result)
        this.snackbar_text = create_transaction_result
        this.snackbar = true
      }
      else {
        this.snackbar_text = 'กรุณาใส่ file'
        this.snackbar = true
      }

      // if (create_transaction_result == 'createTransaction') {
      //   this.snackbar_text = 'ส่งข้อมูลเรียนร้อย'
      //   this.snackbar = true
      //   this.$refs.form.reset()
      //   this.getMobileUsed()
      //   for (let i = 0; i < user.roles.length; i++) {
      //     if (user.roles[i] == 'user' || user.roles[i] == 'sale') {
      //       this.$router.push('/sale')
      //     } else if (user.roles[i] == 'admin_page') {
      //       this.$router.push('/createTransaction')
      //     }
      //   }
      // } else {
      //   this.snackbar_text = 'เกิดปัญหาในการส่งข้อมูล'
      //   this.snackbar = true
      // }
    },
    go_to_add_datapool() {
      this.$router.push('/addDataPool')
    }
  }
}
