//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    username: '',
    password: '',
    snackbar: false,
    snackbar_text: 'username/password ไม่ถูกต้อง',
    isloading: false,
    errorMessage: '',
  }),
  mounted () {
    console.log('in login')
  },
  methods: {
    getSecondsToTomorrow() {
      let now = new Date();
    
      // tomorrow date
      let tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1);
    
      let diff = tomorrow - now; // difference in ms
      return Math.round(diff / 1000); // convert to seconds
    },
    async login () {
      this.isloading = true
      console.log('start login ')
      const login_result = await this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      })

      if (login_result == null) {
        this.snackbar = true
      }

      this.$cookie.set('user', JSON.stringify(login_result), { expires: '1D'})

      for (let i = 0; i < login_result.roles.length; i++) {
        console.log(login_result.roles[i])
        if (login_result.roles[i] == 'user' || login_result.roles[i] == 'sale') {
          this.$router.push('/sale')
        } else if (login_result.roles[i] == 'web_admin') {
          this.$router.push('/admin')
        } else if (login_result.roles[i] == 'admin_page') {
          this.$router.push('/createTransaction')
        }
        else {
          console.log('no role')
        }
      }
      this.isloading = false
    }
  }
}
