//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    snackbar: false,
    snackbar_text: '',
    valid: true,
    name: '',
    telephone: '',
    houseNo: '',
    province: '',
    provinceList: [],
    district: '',
    districtList: [],
    subdistrict: '',
    subdistrictList: [],
    postcode: '',
    thailandAddress: [],
    status: 'A',
    statusList: ['A', 'D', 'S'],
    date_pick: '',
    address1: '',
    address2: '',
    source: '',
    saleList: [],
    saleSelected: {},
    telephoneRules: [
      v => !!v || 'กรุณาใส่เบอร์โทรศัพท์',
      v => Number.isInteger(Number(v)) || "กรุณาใส่เป็นเลขเท่านั้น",
    ],
    postcodeRules: [
      v => !!v || 'กรุณาใส่รหัสไปรษณีย์',
      v => Number.isInteger(Number(v)) || "กรุณาใส่เป็นเลขเท่านั้น",
    ],
    priceRules: [
      v => !!v || 'กรุณากรอกราคาสินค้า',
      v => Number.isInteger(Number(v)) || "กรุณาใส่เป็นเลขเท่านั้น",
    ],
    productType: '',
    productTypeList: ['Beta-x', 'BC', 'LIV', 'BH'],
    productTransaction: '',
    paymentMethod: '',
    paymentMethodList: ['โอนเงิน', 'เก็บเงินปลายทาง', 'อื่นๆ'],
    paymentMethodOther: '',
    productPrice: 0,
    note: '',
    moneySlip: '',
    mobile_used_list: [],
    sourceList: [],
  }),
  mounted () {
    console.log('init mount')
    console.log(this.province)
    this.getSaleListAdmin()
    this.getSourceList()
    this.thailandAddress = require('../assets/thailand_address.json')
    // this.getMobileUsed()
    this.$refs.form.reset()
    for (let i = 0; i < this.thailandAddress.length; i++) {
      if (!this.provinceList.includes(this.thailandAddress[i].chw_tname)) {
        this.provinceList.push(this.thailandAddress[i].chw_tname)
      }
    }
    this.provinceList.sort()
    this.status = this.statusList[0]
    console.log('debug')
    console.log(this.status)
    console.log(this.statusList)
    
  },
  methods: {
    checkTelephone (v) {
      return this.mobile_used_list.includes(v)
    },
    generateDistrictList(province) {
      this.districtList = []
      this.subdistrictList = []
      this.district = ''
      this.subdistrict = ''
      for (let i = 0; i < this.thailandAddress.length; i++) {
        if (this.thailandAddress[i].chw_tname === province) {
          if (!this.districtList.includes(this.thailandAddress[i].amp_name)) {
            this.districtList.push(this.thailandAddress[i].amp_name)
          }
        }
      }
      this.districtList.sort()
    },
    async getSourceList () {
      console.log('get source')
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_kerry = true

      const source_list_result = await this.$store.dispatch('getSourceList', {
        uuid: user.uuid,
      })

      this.sourceList = source_list_result
      // this.source = setTimeout(source_list_result[0], 2000)

      // setTimeout(function(){
      //   this.source = source_list_result[0].username
      //   console.log('this.source')
      //   console.log(this.source)
      // }, 500)

      this.source = source_list_result[0].username
      console.log(this.source)
    },
    generateSubdistrictList(district) {
      this.subdistrictList = []
      this.subdistrict = ''
      for (let i = 0; i < this.thailandAddress.length; i++) {
        if (this.thailandAddress[i].chw_tname === this.province && this.thailandAddress[i].amp_name === district) {
          if (!this.subdistrictList.includes(this.thailandAddress[i].name_t)) {
            this.subdistrictList.push(this.thailandAddress[i].name_t)
          }
        }
      }
      this.subdistrictList.sort()
    },
    objectToFormData (obj = {}) {
        let formData = new FormData()
            for (let key in obj) {
                if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
                for (let item in obj[key]) {
                    if (typeof obj[key][item] === 'object' && !(obj[key][item] instanceof File)) {
                        for (let index in obj[key][item]) {
                            formData.append(`${key}[${item}][${index}]`, obj[key][item][index])
                        }
                    } else {
                        formData.append(`${key}[${item}]`, obj[key][item])
                    }
                }
            } else {
                formData.append(key, obj[key])
            }
        }
        return formData
    },
    async createTransaction () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)

      let param_obj = {
        uuid: user.uuid,
        name: this.name,
        telephone: this.telephone,
        houseno: this.houseNo,
        province: this.province,
        district: this.district,
        subdistrict: this.subdistrict,
        postcode: this.postcode,
        product_type: this.productType,
        product: this.productTransaction,
        price: this.productPrice,
        payment_method: null,
        note: null,
        slip: null,
        date_pick: '',
      }
      if (this.paymentMethod == 'อื่นๆ') {
        param_obj.payment_method = this.paymentMethodOther
      }
      else {
        param_obj.payment_method = this.paymentMethod
      }
      if (this.note != '') {
        param_obj.note = this.note
      }
      if (this.moneySlip != '') {
        param_obj.slip = this.moneySlip
      }

      let params = this.objectToFormData(param_obj)

      const create_transaction_result = await this.$store.dispatch('createTransaction', params)

      console.log(create_transaction_result)
      if (create_transaction_result == 'createTransaction') {
        this.snackbar_text = 'ส่งข้อมูลเรียนร้อย'
        this.snackbar = true
        this.$refs.form.reset()
        this.getMobileUsed()
        for (let i = 0; i < user.roles.length; i++) {
          if (user.roles[i] == 'user' || user.roles[i] == 'sale') {
            this.$router.push('/sale')
          } else if (user.roles[i] == 'admin_page') {
            this.$router.push('/createTransaction')
          }
        }
      } else {
        this.snackbar_text = 'เกิดปัญหาในการส่งข้อมูล'
        this.snackbar = true
      }
    },
    async getMobileUsed () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      const mobile_used_result = await this.$store.dispatch('getMobileUsed', {
        uuid: user.uuid,
      })

      this.mobile_used_list = []
      for (let i = 0; i < mobile_used_result.length; i++) {
        this.mobile_used_list.push(mobile_used_result[i].mobile)
      }

      console.log(this.mobile_used_list)
    },
    validate () {
      let result = this.$refs.form.validate()
      
      let check_mobile_result = this.checkTelephone(this.telephone)

      if (check_mobile_result) {
        result = false
        this.snackbar_text = 'ห้ามใส่เบอร์ซ้ำกับที่ส่งในวันนี้'
        this.snackbar = true
      }
      
      if (result) {
        this.createTransaction()
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    async getSaleListAdmin () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale_user = true

      console.log(user)
      const sale_list_result = await this.$store.dispatch('getSaleListAdmin', {
        uuid: user.uuid,
      })

      this.saleList = sale_list_result
      this.loading_sale_user = false

      console.log(this.saleList)
    },
    async createTransaction () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)

      let param_obj = {
        uuid: user.uuid,
        name: this.name,
        mobile: this.telephone,
        address1: this.address1,
        address2: this.address2,
        province: this.province,
        district: this.district,
        subdistrict: this.subdistrict,
        postcode: this.postcode,
        price: this.productPrice,
        status: this.status,
        sale_id: null,
        noti_date: null,
        note: null,
        slip: null,
        source: this.source,
      }
      if (this.note != '') {
        param_obj.note = this.note
      }
      if (this.saleSelected != null) {
        param_obj.sale_id = this.saleSelected
      }
      if (this.date_pick != '') {
        param_obj.noti_date = this.date_pick
      }

      let params = this.objectToFormData(param_obj)

      const create_transaction_result = await this.$store.dispatch('addLeadManual', params)

      console.log(create_transaction_result)
      if (create_transaction_result == 'add lead success') {
        this.snackbar_text = 'ส่งข้อมูลเรียนร้อย'
        this.snackbar = true
        this.$refs.form.reset()
        this.getMobileUsed()
        this.$router.push('/end')
      } else {
        this.snackbar_text = 'เกิดปัญหาในการส่งข้อมูล'
        this.snackbar = true
      }
    },
  }
}
