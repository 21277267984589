//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    chartOptions: {
      chart: {
        type: 'column',
        height: 1000,
        width: 1600,
      },
      title: {
          text: 'ยอดขายรายคน (บาท)',
      },
      xAxis: {
          categories: ['Arsenal', 'Chelsea', 'Liverpool', 'Manchester United']
      },
      yAxis: {
          title: {
              text: 'ยอดขาย'
          },
          stackLabels: {
              enabled: true,
              style: {
                  fontWeight: 'bold',
                  textOutline: 'none'
              }
          }
      },
      // legend: {
      //     align: 'left',
      //     x: 70,
      //     verticalAlign: 'top',
      //     y: 70,
      //     floating: true,
      //     borderColor: '#CCC',
      //     borderWidth: 1,
      //     shadow: false
      // },
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
          }
      },
      series: [{
          name: 'BPL',
          data: [3, 5, 1, 13,1,1,1,1,1,1,1,1,15,21,23,59]
      }, {
          name: 'FA Cup',
          data: [14, 8, 8, 12]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }, {
          name: 'CL',
          data: [0, 2, 6, 3]
      }]
      // series: []
    },
    tab: 'sale_value',
    type: 'overview',
    saleSelected: '0',
    snackbar: false,
    snackbar_text: '',
    saleList: [],
    loading_sale_user: false,
    dashboard_overview_total_price: [],
    dashboard_overview_total_transaction: [],
    dashboard_sale_total_price: [],
    dashboard_sale_total_transaction: [],
    dashboard_sale_specific_total_price: [],
    dashboard_sale_specific_total_transaction: [],
    year_list: ['2022', '2023', '2024'],
    month_list: ['1','2','3','4','5','6','7','8','9','10','11','12'],
    unique_month: [],
    overview_unique_month: [],
    selected_year: '2023',
    selected_month: '1',
  }),
  mounted () {
    // this.getLeadListAdmin()
    // this.getSaleTransaciontAdmin()
    this.getSaleListAdmin()
    this.getDashboardOverview()
    // this.getDashboardSale()
    // this.getSaleListMonth()
    // this.getRemoveReasonAdmin()
    // this.getAppointmentAdmin()
  },
  methods: {
    async getSaleListAdmin () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale_user = true

      console.log(user)
      const sale_list_result = await this.$store.dispatch('getSaleListAdmin', {
        uuid: user.uuid,
      })

      this.saleList = sale_list_result
      this.saleSelected = sale_list_result[0].uid
      this.loading_sale_user = false

      // console.log(saleList)
    },
    // updateSeries() {
    //   for (var i = 0; i < this.info.stock_prices.length; i++) {
    //     this.mySeries.push([this.info.stock_prices[i].volume]);
    //   } 

    //   this.chartOptions.series.data: this.mySeries;
    // },
    set_sale_overview_price(){
      
      console.log(this.unique_month)
      if (this.type == 'overview') {
        this.chartOptions.series = this.dashboard_overview_total_price
        this.chartOptions.title.text = 'ยอดขายทั้งหมด (บาท)'
        this.chartOptions.xAxis.categories = this.overview_unique_month
      }
      else if(this.type == 'person') {
        this.chartOptions.series = this.dashboard_sale_total_price
        this.chartOptions.title.text = 'ยอดขายรายคน (บาท)'
        this.chartOptions.xAxis.categories = this.unique_month
      }
    },
    set_sale_overview_transaction(){
      if (this.type == 'overview') {
        this.chartOptions.series = this.dashboard_overview_total_transaction
        this.chartOptions.title.text = 'ยอดขายทั้งหมด (จำนวนยอด)'
        this.chartOptions.xAxis.categories = this.overview_unique_month
      }
      else if(this.type == 'person') {
        this.chartOptions.series = this.dashboard_sale_total_transaction
        this.chartOptions.title.text = 'ยอดขายรายคน (จำนวนยอด)'
        this.chartOptions.xAxis.categories = this.unique_month
      }
    },
    async getDashboardOverview () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale_user = true

      console.log(user)
      const dashboard_overview_result = await this.$store.dispatch('getDashboardOverview', {
        uuid: user.uuid,
      })

      console.log(dashboard_overview_result)

      let dashboard_overview_total_price = []
      let dashboard_overview_total_transaction = []

      this.overview_unique_month = [...new Set(dashboard_overview_result.map(item => item.month))]; // [ 'A', 'B']

      let saleList = [undefined]

      for (let i = 0; i < saleList.length; i++) {
        let data_total_price = []
        let data_total_transaction = []
        for (let j = 0; j < this.overview_unique_month.length; j++) {
          let counter = 0
          for (let k = 0; k < dashboard_overview_result.length; k++) {
            // if (dashboard_overview_result[j].month == unique_month[i].name && this.saleList[k].name == dashboard_overview_result[j].name){
            if (dashboard_overview_result[k].month == this.overview_unique_month[j] && saleList[i] == dashboard_overview_result[k].name){
              data_total_price.push(parseFloat(dashboard_overview_result[k].total_price))
              data_total_transaction.push(parseFloat(dashboard_overview_result[k].total_transaction))
              counter += 1
            }
          }
          if (counter == 0) {
            data_total_price.push(0)
            data_total_transaction.push(0)
          }
        }
        dashboard_overview_total_price.push(
          {
            name: saleList[i],
            data: data_total_price,
            visible: true,
          }
        )
        dashboard_overview_total_transaction.push(
          {
            name: saleList[i],
            data: data_total_transaction,
            visible: true,
          }
        )
      }
      // for (let i = 0; i < this.saleList.length; i++) {
      //   let data_total_price = []
      //   let data_total_transaction = []
      //   for (let j = 0; j < unique_month.length; j++) {
      //     let counter = 0
      //     for (let k = 0; k < dashboard_overview_result.length; k++) {
      //       // if (dashboard_overview_result[j].month == unique_month[i].name && this.saleList[k].name == dashboard_overview_result[j].name){
      //       if (dashboard_overview_result[k].month == unique_month[j] && this.saleList[i].name == dashboard_overview_result[k].name){
      //         data_total_price.push(parseFloat(dashboard_overview_result[k].total_price))
      //         data_total_transaction.push(parseFloat(dashboard_overview_result[k].total_transaction))
      //         counter += 1
      //       }
      //     }
      //     if (counter == 0) {
      //       data_total_price.push(0)
      //       data_total_transaction.push(0)
      //     }
      //   }
      //   dashboard_overview_total_price.push(
      //     {
      //       name: this.saleList[i].name,
      //       data: data_total_price,
      //       visible: true,
      //     }
      //   )
      //   dashboard_overview_total_transaction.push(
      //     {
      //       name: this.saleList[i].name,
      //       data: data_total_transaction,
      //       visible: true,
      //     }
      //   )
      // }
      this.dashboard_overview_total_price = dashboard_overview_total_price
      this.dashboard_overview_total_transaction = dashboard_overview_total_transaction

      this.chartOptions.series = dashboard_overview_total_price
      this.chartOptions.xAxis.categories = this.overview_unique_month
      this.loading_sale_user = false

      // console.log(saleList)
    },
    async getDashboardSale () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale_user = true

      const dashboard_sale_result = await this.$store.dispatch('getDashboardSale', {
        uuid: user.uuid,
        sale_id: this.saleSelected
      })

      let dashboard_sale_total_price = []
      let dashboard_sale_total_transaction = []

      this.unique_month = [...new Set(dashboard_sale_result.map(item => item.month))];
      let unique_product_type = [...new Set(dashboard_sale_result.map(item => item.product_type))]; 

      for (let i = 0; i < unique_product_type.length; i++) {
        let data_total_price = []
        let data_total_transaction = []
        for (let j = 0; j < this.unique_month.length; j++) {
          let counter = 0
          for (let k = 0; k < dashboard_sale_result.length; k++) {
            if (dashboard_sale_result[k].month == this.unique_month[j] && unique_product_type[i] == dashboard_sale_result[k].product_type){
              data_total_price.push(parseFloat(dashboard_sale_result[k].total_price))
              data_total_transaction.push(parseFloat(dashboard_sale_result[k].total_transaction))
              counter += 1
            }
          }
          if (counter == 0) {
            data_total_price.push(0)
            data_total_transaction.push(0)
          }
        }
        dashboard_sale_total_price.push(
          {
            name: unique_product_type[i],
            data: data_total_price,
            visible: true,
          }
        )
        dashboard_sale_total_transaction.push(
          {
            name: unique_product_type[i],
            data: data_total_transaction,
            visible: true,
          }
        )
      }

      this.dashboard_sale_total_price = dashboard_sale_total_price
      this.dashboard_sale_total_transaction = dashboard_sale_total_transaction

      this.chartOptions.series = dashboard_sale_total_price
      this.chartOptions.xAxis.categories = this.unique_month
      this.chartOptions.title.text = 'ยอดขายรายคน (บาท)'
      this.loading_sale_user = false
    },
    async getDashboardSaleSpecific () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      this.loading_sale_user = true

      const dashboard_sale_result = await this.$store.dispatch('getDashboardSaleSpecific', {
        uuid: user.uuid,
        sale_id: this.saleSelected,
        month: this.selected_month,
        year: this.selected_year,
      })

      let dashboard_sale_total_price = []
      let dashboard_sale_total_transaction = []

      this.unique_month = [...new Set(dashboard_sale_result.map(item => item.month))];
      let unique_product_type = [...new Set(dashboard_sale_result.map(item => item.product_type))]; 

      for (let i = 0; i < unique_product_type.length; i++) {
        let data_total_price = []
        let data_total_transaction = []
        for (let j = 0; j < this.unique_month.length; j++) {
          let counter = 0
          for (let k = 0; k < dashboard_sale_result.length; k++) {
            if (dashboard_sale_result[k].month == this.unique_month[j] && unique_product_type[i] == dashboard_sale_result[k].product_type){
              data_total_price.push(parseFloat(dashboard_sale_result[k].total_price))
              data_total_transaction.push(parseFloat(dashboard_sale_result[k].total_transaction))
              counter += 1
            }
          }
          if (counter == 0) {
            data_total_price.push(0)
            data_total_transaction.push(0)
          }
        }
        dashboard_sale_total_price.push(
          {
            name: unique_product_type[i],
            data: data_total_price,
            visible: true,
          }
        )
        dashboard_sale_total_transaction.push(
          {
            name: unique_product_type[i],
            data: data_total_transaction,
            visible: true,
          }
        )
      }

      console.log(dashboard_sale_total_price)
      this.dashboard_sale_specific_total_price = dashboard_sale_total_price
      this.dashboard_sale_specific_total_transaction = dashboard_sale_total_transaction

      this.chartOptions.series = dashboard_sale_total_price
      this.chartOptions.xAxis.categories = this.unique_month
      this.chartOptions.title.text = 'ยอดขายรายคน (บาท)'
      this.loading_sale_user = false
    },
    uncheck_all() {
      this.chartOptions.series.forEach((s) => {
          if (s.visible) {
              s.visible = false
          }
      });
    },
    check_all() {
      this.chartOptions.series.forEach((s) => {
          s.visible = true
      });
    },
  }
}
