//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    customer_detail: false,
    remove_dialog: false,
    remove_reason: '',
    snackbar: false,
    snackbar_text: '',
    dataPool_daily : [],
    dataPool_appoint : [],
    dataPool_sale : [],
    dataPool_waiting : [],
    customer_detail_sale_transaction: [],
    customer_detail_data_pool: [],
    latest_price: [],
    mobileSelected: '',
    postponeSelected: '',
    statusSelected: '',
    date_pick: '',
    appointment_note: '',
    appointment_choice: '',
    min_date: '2022-01-01',
    max_date: '2100-01-01',
    dataPool_daily_Header : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
    ],
    dataPool_appoint_Header : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'วันแจ้งเตือน',
        align: 'start',
        value: 'noti_date',
      },
      {
        text: 'deadline',
        align: 'start',
        value: 'deadline',
      },
    ],
    dataPool_waiting_Header : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'วันแจ้งเตือน',
        align: 'start',
        value: 'noti_date',
      },
      {
        text: 'deadline',
        align: 'start',
        value: 'deadline',
      },
    ],
    dataPool_sale_Header : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'วันแจ้งเตือน',
        align: 'start',
        value: 'noti_date',
      },
    ],
    customer_detail_data_pool_Header : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'วันที่รับข้อมูลเข้าระบบ',
        align: 'start',
        value: 'date',
      },
      {
        text: 'ที่อยู่',
        align: 'start',
        value: 'address1',
      },
      {
        text: 'จังหวัด',
        align: 'start',
        value: 'province',
      },
      {
        text: 'ราคา',
        align: 'start',
        value: 'amount',
      },
      {
        text: 'source',
        align: 'start',
        value: 'source',
      },
      {
        text: 'product',
        align: 'start',
        value: 'product',
      },
      {
        text: 'ครั้งที่นัด',
        align: 'start',
        value: 'appoint_count',
      },
    ],
    customer_detail_sale_transaction_Header : [
      {
        text: 'ชื่อลูกค้า',
        align: 'start',
        value: 'name',
      },
      {
        text: 'เบอร์โทรศัพท์',
        align: 'start',
        value: 'mobile',
      },
      {
        text: 'บ้านเลขที่',
        align: 'start',
        value: 'address1',
      },
      {
        text: 'ตำบล',
        align: 'start',
        value: 'subdistrict',
      },
      {
        text: 'อำเภอ',
        align: 'start',
        value: 'district',
      },
      {
        text: 'จังหวัด',
        align: 'start',
        value: 'province',
      },
      {
        text: 'รหัสไปรษณีย์',
        align: 'start',
        value: 'postcode',
      },
      {
        text: 'วันที่สั่งสินค้า',
        align: 'start',
        value: 'insert_time',
      },
      {
        text: 'ชนิดผลิตภัณฑ์',
        align: 'start',
        value: 'product_type',
      },
      {
        text: 'ผลิตภัณฑ์',
        align: 'start',
        value: 'product',
      },
      {
        text: 'ราคา',
        align: 'start',
        value: 'price',
      },
      {
        text: 'ค่าใช้จ่ายอื่นๆ',
        align: 'start',
        value: 'price_other',
      },
      {
        text: 'วิธีการชำระเงิน',
        align: 'start',
        value: 'payment_method',
      },
      {
        text: 'source',
        align: 'start',
        value: 'source',
      },
    ],
    appointment: false,
    can_appointment: true,
    note: '',
    note_admin: '',
  }),
  mounted () {
    let currentDate = new Date().toJSON().slice(0, 10);
    let date = new Date();
    date.setDate(date.getDate() + 7);
    // date.setDate(date.getDate() + 99999);
    this.date_pick = currentDate
    this.min_date = currentDate
    this.max_date = date.toJSON().slice(0, 10);
    this.getLeadList()
  },
  methods: {
    convert_date_format(date) {
      let date_split = date.split('-')
      return (date_split[2] + '-' + date_split[1] + '-' + date_split[0])
    },
    getColor(date_string) {
      let now = Date.now()
      let date = Date.parse(date_string)
      console.log(date)
      console.log(now)
      if (date < now) {
        return "red"
      }
      else {
        return "green"
      }
    },
    make_appointment () {
      this.appointment = true
      console.log(this.appointment)
    },
    countUnique(iterable) {
      return new Set(iterable).size;
    },
    go_to_createTransaction() {
      console.log('debug')
      console.log(this.customer_detail_data_pool)
      let source_all = []
      for (let i = 0; i < this.customer_detail_data_pool.length; i++) {
        source_all.push(this.customer_detail_data_pool[i].source)
      }
      if (this.countUnique(source_all) > 1) {
        // console.log("source เกิน")
        this.snackbar_text = 'source ข้อมูลมากกว่า 1 แห่ง กรุณาติดต่อหัวหน้าทีม'
        this.snackbar = true
      }
      else {
        if (this.customer_detail_sale_transaction.length > 0) {
          this.$store.commit('SET_saleUpdateTransaction', this.customer_detail_sale_transaction[0])
        }
        else {
          this.$store.commit('SET_saleUpdateTransaction', {})
        }
        this.$store.commit('SET_source', this.customer_detail_data_pool[this.customer_detail_data_pool.length - 1].source)
        this.$router.push('/createTransaction')
      }
    },
    selectRow(value) {
      console.log('select row')
      console.log(value)
      this.date_from = value.noti_date
      this.mobileSelected = value.mobile
      this.postponeSelected = value.appoint_count
      this.statusSelected = value.status
      this.note = value.note
      this.note_admin = value.note_admin
      this.customer_detail = true
      this.get_customer_detail()
      console.log(this.note_admin)
    },
    async getLeadList () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      console.log(user)
      const lead_list_result = await this.$store.dispatch('getLeadList', {
        uuid: user.uuid,
      })

      this.dataPool_daily = lead_list_result['D']
      this.dataPool_appoint = lead_list_result['P']
      
      // set appoint limit = 800
      let appoint_limit = 999999
      if (this.dataPool_appoint.length >= appoint_limit) {
        this.can_appointment = false
      }
      this.dataPool_sale = lead_list_result['S']
      this.dataPool_waiting = lead_list_result['W']

      console.log(lead_list_result)
    },
    async get_customer_detail () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      let date = new Date();

      const customer_detail = await this.$store.dispatch('customerDetail', {
        uuid: user.uuid,
        mobile: this.mobileSelected
      })

      this.customer_detail_sale_transaction = customer_detail.saleTransaction
      this.customer_detail_data_pool = customer_detail.dataPool
      this.latest_price = customer_detail.latestPrice[0]

      if (this.customer_detail_data_pool[0].status == 'W') {
        date.setDate(date.getDate() + 15);
      } else {
        date.setDate(date.getDate() + 15);
      }

      this.max_date = date.toJSON().slice(0, 10);

      console.log(customer_detail)
    },
    async get_change_appointment_date () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      let appointment_count = 99999

      if (this.customer_detail_data_pool[0].status == 'W') {
        // if (parseInt(this.latest_price.price) >= 5000) {
        //   appointment_count = 2
        // }
        // else {
        //   appointment_count = 2
        // }
        appointment_count = 99999
      }
      console.log(user)
      if (this.can_appointment) {
        if (this.customer_detail_data_pool[0]['appoint_count'] < appointment_count) {
          if (this.appointment_choice != ''){
            const change_date_result = await this.$store.dispatch('make_appointment', {
              uuid: user.uuid,
              mobile: this.mobileSelected,
              from: this.date_from,
              to: this.date_pick,
              note: this.appointment_note,
              choice: this.appointment_choice,
            })

            console.log(change_date_result)
            this.getLeadList()
            this.appointment_note = ''
            this.appointment_choice = ''
            this.customer_detail = false
            this.appointment = false
          }
          else {
            this.snackbar_text = 'กรุณาระบุเหตุผลการเลื่อนนัด'
            this.snackbar = true
          }
        }
        else {
          this.snackbar_text = 'ไม่สามารถเลื่อนนัดได้เนื่องจากเกินโควต้าที่กำหนด'
          this.snackbar = true
        }
      }
      else {
        this.snackbar_text = 'ไม่สามารถทำรายการได้ เนื่องจากรายชื่อนัดมีเยอะเกินไป (800 รายชื่อ)'
        this.snackbar = true
      }
    },
    async saleChangeStatusToA () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      if (this.remove_reason != '') {
        const change_date_result = await this.$store.dispatch('saleChangeStatusToA', {
          uuid: user.uuid,
          mobile: this.mobileSelected,
          from: this.date_from,
          to: this.date_pick,
          note: this.appointment_note,
          remove_reason: this.remove_reason,
        })

        console.log(change_date_result)
        this.getLeadList()
        this.appointment_note = ''
        this.customer_detail = false
        this.appointment = false
        this.remove_reason = ''
        this.remove_dialog = false
      }
      else {
        this.snackbar_text = 'กรุณาใส่เหตุผลการนำข้อมูลออก'
        this.snackbar = true
      }
    },
    async saleChangeStatusToI () {
      const user_cookie = this.$cookie.get('user');
      const user = JSON.parse(user_cookie)

      if (this.remove_reason != '') {
        const change_date_result = await this.$store.dispatch('saleChangeStatusToI', {
          uuid: user.uuid,
          mobile: this.mobileSelected,
          from: this.date_from,
          to: this.date_pick,
          note: this.appointment_note,
          remove_reason: this.remove_reason,
        })

        console.log(change_date_result)
        this.getLeadList()
        this.appointment_note = ''
        this.customer_detail = false
        this.appointment = false
        this.remove_reason = ''
        this.remove_dialog = false
      }
      else {
        this.snackbar_text = 'กรุณาใส่เหตุผลการนำข้อมูลออก'
        this.snackbar = true
      }
    },
  }
}
